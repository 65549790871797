
import { Container, Row } from "react-bootstrap"

import Col from 'react-bootstrap/Col';
import img1 from '../../assets/loc.svg';
import SearchForms from './form/searchform'
import bannerImg from '../../assets/banner_search_pin.svg'

export default function BannerHome() {
    return (
            <Container className='banner'>
            <Row>
                <Col lg={6} md={12} className="banner-item">
                    <img src={bannerImg} alt="banner_pin" className="banner-img"/>
                    
                        <h5 className="animate-charcter">Pincodes search</h5>
                        <p>Search among 156000+ PINcodes here.</p>
                        {/* <p>LOCATE ANY POST OFFICE/Pincode IN INDIA<br/>
(Select State then District then Post Office to see Pincode Details)
<br/><br/>
eHelpers has over 1,54,500 Post Offices/Pincode Listed.</p> */}

                </Col>
                <Col lg={6} className="banner-item">
                    {/* <img src={img1} alt="banner image"/> */}
                    <SearchForms/>
                </Col>
            </Row>

        </Container>
    )
}