import { Modal, Button, Container, Row } from "react-bootstrap";
import { useState } from "react";
function PincodeDetails({isShow, pincodes}) {
  const [show, setShow] = useState(isShow);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <Modal show={show} onHide={handleClose} size="lg" className="modal-pincode-details">
      <Modal.Header closeButton>
        <Modal.Title>
          {pincodes.length > 1 ? `There are ${pincodes.length} Post-offices with similar PinCode ${pincodes[0].pincode}` : `Pincode details of ${pincodes[0].office_name} post-office`}
          
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {pincodes.map((pincode, key) => (
          <div className="d-block pincode-details-body">
            <h5>Post Office: {pincode.office_name} </h5>
            <span>Circle Name: {pincode.circle_name}</span><br/>
            <span>Region Name: {pincode.region_name}</span><br/>
            <span>Division Name: {pincode.division_name}</span><br/>
            <span>Office Name: {pincode.office_name}</span><br/>
            <span>Pincode: {pincode.pincode}</span><br/>
            <span>Office Type: {pincode.office_type}</span><br/>
            <span>Delivery: {pincode.delivery}</span><br/>
            <span>District: {pincode.district}</span><br/>
            <span>State Name: {pincode.state_name}</span><br/>
            <span>Address: {pincode.address}</span>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>

        <div>

        </div>  
        {/* <Button variant="secondary" onClick={handleClose}>Close</Button> */}
          <Button variant="primary" onClick={handleClose}>Search next</Button>
      </Modal.Footer>
    </Modal>

      )
}

export default PincodeDetails;