import { useEffect, useState } from 'react';
import { Container, Row , Col, Form, Button , Modal} from 'react-bootstrap';
import Select from 'react-select'
// import PincodeDetails from './pincodedetails';
import PincodeDetails from '../pincodedetails';
import './searchform.css'


function SearchForms(){
//   const url ="https://ehelpers.in/api/";
  const url ="https://www.ehelpers.in/api/";

  const [states, setStates] = useState([]);
  const [districts , setDistrict] = useState([]);
  const [postoffices, setPostoffice] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedPostOffice, setSelectedPostOffice] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [enteredPincode, setEnteredPincode] = useState("");
  const [pincodes, setPincodes] = useState([]);
  const [show, setShow] = useState(false);
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI5NDE1OTcyMjU5MCIsIm5hbWUiOiJlSGVscGVycyIsImlhdCI6MTUxNjIzOTAyMn0.4z-wyUvhietCZLLh2_P1DeYckTTlWo0iApIINHee8UpKcrj7CUs' },
    body: {}
  };
  

  useEffect(() => {
    fetch(url+ "state", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(typeof data.states != "undefined"){
            setStates(data.states)
        }else if(typeof data.errorMessage != "undefined"){
            //alert(data.errorMessage)
        }
      }).catch(rejected => {console.log(rejected);});
  }, []);
  function resetFom(position){
     if(position == 2){
          setPincodes([])
          setPostoffice([])
     }else if(position == 3){
          setPincodes([])
     }else{
          setSelectedDistrict("")
          setPostoffice([])
          setDistrict([])
          setPincodes([])
     }
     setShow(false)
     setEnteredPincode("")
  }
  function getDistrict(statename){
     setSelectedState (statename)
     resetFom(1)
     fetch(url+ "district?state="+ statename, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(typeof data.districts != "undefined"){
            setDistrict(data.districts)
        }else if(typeof data.errorMessage != "undefined"){
            alert(data.errorMessage)
        }
      }).catch(rejected => {});
  }

  function getPostoffices(districtname){
     setSelectedDistrict(districtname)
     resetFom(2)
     fetch(url+ "po?state="+ selectedState + "&district=" + districtname, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(typeof data.pos != "undefined"){
            setPostoffice(data.pos)
        }else if(typeof data.errorMessage != "undefined"){
            alert(data.errorMessage)
        }
      }).catch(rejected => {});
  }

  function onSelectPostOffice(postoffice){
    setSelectedPostOffice(postoffice)
  }

  function getPincode(){
     resetFom(3)
     let api=url+ "pincode?state="+ selectedState + "&district=" + selectedDistrict + "&postoffice=" + selectedPostOffice
     if (enteredPincode.length == 6){
        api =url+ "pincode?pincode="+ enteredPincode
     }

     fetch(api, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if(typeof data.pincodes != "undefined"){
          setPincodes(data.pincodes)
          setShow(true)
        }else if(typeof data.errorMessage != "undefined"){
            alert(data.errorMessage)
        }
      }).catch(rejected => {});
  }


     return(
          <>
            <div className='search-form d-block'>
                <div className="search-form-inner">
                    <Form.Select onChange={e =>{getDistrict(e.target.value)}}>
                        <option key="select" title="select" value="0" >Select State...</option>
                        {states.map((state, stateid) => (
                            <option key={stateid} title={state.name} value={state.name}>
                                    {state.name}
                            </option>
                        ))
                        }
                    </Form.Select>
                
                
                    <Form.Select onChange={e =>{getPostoffices(e.target.value)}}>
                        <option key="select" title="select" value="0" >Select District...</option>
                        {districts.map((district, districtid) => (
                            <option key={districtid} title={district.name} value={district.name}>
                                    {district.name}
                            </option>
                        ))
                        }
                    </Form.Select>
                
                
                    <Form.Select onChange={e =>{onSelectPostOffice(e.target.value)}}>
                        <option key="select" title="select" value="0" >Select Post Office...</option>
                        {postoffices.map((postoffice, Postofficeid) => (
                            <option key={Postofficeid} title={postoffice.name} value={postoffice.name}>
                                    {postoffice.name}
                            </option>
                        ))
                        }
                    </Form.Select>  

                    <div className="or-block">
                        <span className='or-text'>
                            OR
                        </span>
                        <span className='or-line'></span>
                    </div>
                    <Form.Control onChange={e =>{
                        e.target.value.length > 6 ? setEnteredPincode(enteredPincode): 
                        setEnteredPincode(e.target.value)}} type='number' placeholder='Enter PINCode...'
                    onKeyDown={ e => e.which === 38 || e.which === 40 ? e.preventDefault() : ""} value={enteredPincode} maxLength="6" >
                        
                    </Form.Control>
                    <Button variant='primary srchbtn' onClick={e =>getPincode()}>SEARCH</Button>
                        
                    {pincodes.length > 0 ? <PincodeDetails isShow={show} pincodes={pincodes}/> : <></>}
                </div>
            </div>
        </>
    )
}



export default SearchForms;