import { Col, Container, Row} from "react-bootstrap";
import './footer.css'



export default function Footerinfo(){

    return(
        <>
            <div className="d-block footerin">
                        <span>
                            Copyright &copy;2023 eHelpers.in | All Rights reserved | Privacy policy | Design & developed by : eHelpers.in
                        </span>
            </div>
        </>
    )
}   