import { Container, Row } from "react-bootstrap"

import Col from 'react-bootstrap/Col';
import './aboutpincode.css'
import { useEffect, useState } from "react";
import imgpincodenumbering from "../../../assets/pincode_numbering.svg"
import imgpincodezone from "../../../assets/indian_pin_c.svg"


export default function AboutPincodes() {
    const url ="https://ehelpers.in/api/";
    
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI5NDE1OTcyMjU5MCIsIm5hbWUiOiJlSGVscGVycyIsImlhdCI6MTUxNjIzOTAyMn0.4z-wyUvhietCZLLh2_P1DeYckTTlWo0iApIINHee8UpKcrj7CUs' },
    body: {}
  };
    
    const [states, setStates] = useState([]);

    useEffect(() => {
        fetch(url+ "state", requestOptions)
          .then((res) => res.json())
          .then((data) => setStates(data.states))
          .catch(err=> {});
      }, []);

    return (
            <Container className='aboutpincodes'>
            <Row>
                <Col lg={8} md={12} className="about-text">
                    <div className="d-block about-text-inner">
                        <h2>Indian PINcode system
                        </h2>
                        <p className="fw-normal">
                        A Postal Index Number (PIN) refers to a six-digit code
                        in the postal code system.
                        
                            The PIN code system completed it's 51 year in August 2023
                        
                        The technique was evolved to remove confusion over inaccurate addresses,
                         similar location names, and several public languages.
                        
                            PIN system also used to simplify the sorting and delivery of mails.
                        </p>
                        <div className="about-text-inner-row">
                            <Col lg={6} md={12} className="aboutzones">
                                <h6>India has total nine zones.</h6>
                                <ul>
                                    <li>Eight pincodes are regional</li>
                                    <li>One zone is functional for indian army.</li>
                                </ul>
                                <h6>First digit of PINs</h6>
                                <ul>
                                    <li>1 & 2 - North Zone</li>
                                    <li>3 & 4 - West Zone</li>
                                    <li>5 & 6 - South Zone</li>
                                    <li>7 & 8 - East Zone</li>
                                    <li>9 is Army postal code (APS) / Field post office (FPO)</li>
                                </ul>
                            </Col>
                            <Col lg={6} md={12} className="about-zones-img">
                                <img src={imgpincodezone} alt="" className="pindetail" />  
                            </Col>
                        </div>
                    </div>
                    <div className="about-text-inner-row">
                        <Col lg={12} md={12} className="about-pin-numering">
                            <div className="d-block about-pin-numering-inner">
                                <h2>Indian PINcode Numbering
                                </h2>
                                <p>Reading of a PINcode</p>
                                <img src={imgpincodenumbering} alt="" className="pindetail" />
                                
                            </div>
                        </Col>
                    </div>
                </Col>
                <Col lg={4} className="about-states">
                    <div className="d-block about-states-inner">
                        <h2>Indian States</h2>
                        <div className="d-block state-list">
                            {states.map((state, stateid) => (
                                <p>
                                        {state.name}
                                </p>
                            ))
                            }
                        </div>
                        <div className="endingline d-block">
                                    <p>eHelpers have more than 156000+ PINcode data.
                                        Search Yours.
                                    </p>
                                </div>
                    </div>

                </Col>
            </Row>
           

        </Container>
    )
}