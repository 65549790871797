



import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import img1 from '../../img1.png';
import "./header.css"
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import logo from '../../assets/logo.png'




function  Header() {
  const [scrollClass, setScrollclass] = useState('');

  function handleOnScroll (e){
    if(window.scrollY > 15){
      setScrollclass ("page-Scrolled")
    }
    else {
      setScrollclass ("")
    }
  }

  useEffect(() => {
        handleOnScroll (null)
        window.addEventListener("scroll" , (e) => handleOnScroll(e)) 
        },
      []);



    return (
      
      <Navbar expand="lg" className={`navbar-light ${scrollClass}`} fixed='top'>
        <Container className='headerContainer'>
          {/* <Navbar.Brand href="/"><span className="animate-charcter">eHelpers</span></Navbar.Brand> */}
          <Navbar.Brand href="/"><img src={logo}/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Link to="/#home" className='navItem nav-link'>Pincode Search</Link>
              {/* <Link to="/" className='navItem nav-link'>Live Clock</Link>
              <Link to="/" className='navItem nav-link'>Calculator</Link> */}
              <Link to="aboutus" className='navItem nav-link'>About eHelpers</Link>
              <Link to='privacypolicy' className='navItem nav-link'>Privacy Policy</Link>
              
              
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
            };
export default Header


  