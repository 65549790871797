
import AboutPincodes from "./aboutpincodes/aboutpincode";
import BannerHome from "./banner";
import Searchform from "./form";
import './home.css'

export default function Home(){
    return(
        <>
        <BannerHome/>
        {/* <Searchform/> */}
        <AboutPincodes/>

       
        </>
        
    )
}