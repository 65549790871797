import { Container, Row } from "react-bootstrap";

import './about.css'

export default function AboutUs (){
    return (
        <>
        <Container className="aboutus">
            <Row>
            <h1>About eHelpers</h1>
           <h2>
            eHelpers: Your Premier Pincode Locator </h2>

            <p>

            <b> Extensive Pincode Database: </b>With 156,000+ pincodes, eHelpers offers an unparalleled resource for locating postal codes across India.</p>

            <p>
            <b>Your One-Stop Destination:</b> eHelpers is your single, comprehensive destination for accurate and up-to-date postal code information, ensuring your location-based searches are hassle-free.</p>


            <p>
            <b>Dedicated to Simplification:</b> Whether you're sending packages, planning your travels, or exploring new areas, we are dedicated to simplifying your pincode searches.</p>

            <p>
            <b> Precision and Reliability:</b> At eHelpers, our commitment is to provide you with precise, reliable, and user-friendly services, making your pincode searches effortless.</p>

            <p>
            <b>Comprehensive Data Meets Convenience: </b>Choose eHelpers for all your pincode needs, where the intersection of comprehensive data and convenience is our hallmark.</p>


            <p>
            <b> Your Go-To Pincode Solution: </b>Thank you for choosing eHelpers as your trusted go-to pincode search solution.</p>
            </Row>
        </Container>
        
        <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
        <br></br>
        </>
    )
}






