import React, { useState, useEffect } from "react";
import './App.css';
import Header from './components/header/header';
import Home from './components/home/home';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Routes,
  BrowserRouter,
  Link,
} from "react-router-dom";
import PrivacyPolicy from "./components/privacypolicy/privacypolicy";
import AboutUs from "./components/aboutus/about";
import Footerinfo from './components/footer/footer'


function App() {
  const router = createBrowserRouter([
    {path: "privacypolicy",element: <PrivacyPolicy/>},
    {path: "aboutus",element: <AboutUs/>},
    {path: "/",element: <Home/>},
    {path: "*",element: <Home/>},
  ]);
  return (
    <>
    <BrowserRouter>
      <Header/>
      <Routes>
            		
            		<Route path="/aboutus" element={<AboutUs/>} />
            		<Route path="/privacypolicy" element={<PrivacyPolicy/>} />
                <Route path="/" element={<Home />} />
                <Route path="*" element={<Home />} />
          		</Routes>
      </BrowserRouter>
      <Footerinfo/>
      {/* <RouterProvider router={router} /> */}
      <div className="area" >
            <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
    </div >
    </>
    
  );
}

export default App;







