import { Container, Row } from "react-bootstrap";

import './privacypolicy.css'

export default function PrivacyPolicy (){
    return (
        <Container className="privacypolicy">
            <Row>
            <h1>Privacy Policy for eHelpers</h1>
            <h6> Last Updated: 15-OCT-2023 </h6>
            <p> Welcome to eHelpers, the online pincode search service provided by us. We are committed to safeguarding your privacy and ensuring the protection of your personal information. This Privacy Policy explains how we collect, use, disclose, and secure the information you provide when using our pincode search website.</p>
            <p>
                By using eHelpers, you agree to the practices outlined in this Privacy Policy. If you do not agree with these practices, please refrain from using our services.
            </p>

            <h2>1. Information We Collect</h2>

            <p>
                1.1. Personal Information: We do not collect personally identifiable information such as your name, email address, or phone number when you use our pincode search service. Your privacy is paramount to us.
            </p>

            <p>
                1.2. Location Data: We may collect approximate location data derived from your browser (If you allow) to provide localized search results. We do not track your precise GPS location.
            </p>

            <h2>
                2. Use of Information
            </h2>
            <p>
                2.1. Pincode Search: We use the information collected to provide you with pincode search results, helping you access location-based information.
            </p>
            <p>
                2.2. Service Improvement: We may analyze website usage data and collect information to enhance and optimize the quality and functionality of our services. This data is aggregated and does not identify individuals.
            </p>
           
            <h2>
                3. Cookies and Tracking Technologies
            </h2>
            <p>
                To enhance your experience on our website, we use cookies and similar tracking technologies. You can manage and delete cookies through your browser settings. For more information, please refer to our Cookie Policy.
            </p>
            <h2>
                4. Data Security
            </h2>
            <p>
                We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no data transmission over the internet or electronic storage is entirely secure, and we cannot guarantee the absolute security of your data.
            </p>
            <h2>
                5. Links to Other Websites
            </h2>
            <p>
                eHelpers may contain links to external websites beyond our control. We are not responsible for the privacy practices of these external sites. We encourage you to review their respective privacy policies.
            </p>
            
            <h2>
                6. Changes to this Privacy Policy
            </h2>
            <p>
                We may update this Privacy Policy to reflect changes in our practices or for legal, operational, or regulatory reasons. We will notify you of any significant changes via our website. We encourage you to review this policy periodically.
            </p>
            <h2>
                7. Contact Us
            </h2>
            <p>
                If you have any questions or concerns regarding this Privacy Policy or the data we collect, please contact us at admin@ehelpers.in
            <br></br>
                By using eHelpers, you agree to the terms outlined in this Privacy Policy. Your continued use of our services constitutes acceptance of any updates or revisions to this policy.
            <br></br>
                Thank you for choosing eHelpers for your pincode search needs. Your privacy is of utmost importance to us.
            </p>
            </Row>
        </Container>
    )
}